import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function ToggleOption({ options, selectedOption, setSelectedOption }) {

  const handleToggle = (event, option) => {
    setSelectedOption(option);
  };

  return (
    <>
      <ToggleButtonGroup
        exclusive
        value={selectedOption}
        color="primary"
        onChange={handleToggle}
        size="medium">
        {options.map(option => (
          <ToggleButton
            key={option.key}
            value={option.key}
            sx={{ borderRadius: "12px" }}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
}