
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TransactionsList from "../common/TransactionsList";
import Transaction from "../../shared/model/transaction";
import SelectOption from "../common/SelectOption";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const options = [
  {
    key: "active",
    label: "Aktiiviset",
    filter: tx => tx.status !== Transaction.Status.Received &&
      tx.status !== Transaction.Status.Cancelled
  },
  {
    key: "finished",
    label: "Menneet",
    filter: tx => tx.status === Transaction.Status.Received ||
      tx.status === Transaction.Status.Cancelled
  },
];

export default function Transactions() {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const navigate = useNavigate();
  const location = useLocation();

  const transactionId = new URLSearchParams(location.search).get("id");

  const setTransactionId = (id) => id === null
    ? navigate("/transactions")
    : navigate(`/transactions?id=${encodeURIComponent(id)}`);

  return (
    <Grid container spacing={8} paddingX={{ xs: 2, md: 4 }} paddingY={4}>
      <Grid item container direction="column" alignItems="center" spacing={2}>
        <Grid item container justifyContent="center" alignItems="center" gap={2}>
          <ReceiptLongIcon fontSize="large" />
          <Typography variant="h4">Kaupat</Typography>
        </Grid>
        <Grid item>
          <SelectOption
            options={options}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </Grid>
      </Grid>
      <Grid item width="100%">
        <TransactionsList
          filterTransactions={selectedOption.filter}
          currentTransactionId={transactionId}
          setCurrentTransactionId={setTransactionId}
        />
      </Grid>
    </Grid>
  );
}

